<template>
	<WaitDialog :isActive="isWaiting" title="Working" />

	<v-navigation-drawer permanent v-model="drawer" :rail="rail" elevation="5" @click="rail = !rail" width="320"
		:color="drawerColor">

		<v-list density="compact" class="mt-3" nav>
			<v-list-item class="text-start" :disabled="rail ? true : false"
				href="https://enumclawpandc.sharepoint.com/:b:/r/sites/FaCTTeam/Shared%20Documents/General/Homer%20Migrated%20Content/UW%20Workbench/UW%20Workbench%20User%20Guide.pdf?csf=1&web=1&e=wWyGUP"
				prepend-icon="mdi-book-open-variant" title="User Guide" value="guide">
			</v-list-item>

			<v-list-item @click="sendDocument()" :disabled="rail ? true : false" class="text-start custom-list-item"
				prepend-icon="mdi-send" title="Send to OnBase" value="onbase"></v-list-item>

			<v-list-item class="text-start" target="_blank" :disabled="rail ? true : false" :href=plrReportUrlPolicy
				prepend-icon="mdi mdi-chart-line" title="Policy Loss Ratio Report" value="report"></v-list-item>

			<v-list-item class="text-start" target="_blank" :disabled="rail ? true : false"
				:href=electronicWorksheetURLValue prepend-icon="mdi-file-document" title="Most Recent Electronic Worksheet"
				value="worksheet"></v-list-item>
		</v-list>

		<template v-slot:append>
			<v-container fluid>
				<v-row justify="end" v-if="!rail">
					<v-btn icon="mdi-chevron-left" variant="text" @click.stop="rail = !rail"></v-btn>
				</v-row>

				<v-row justify="center">
					<v-btn icon="mdi-chevron-right" variant="text" @click.stop="rail = !rail" v-if="rail"></v-btn>
				</v-row>
			</v-container>
		</template>
	</v-navigation-drawer>

	<v-container fluid class="pa-3 ma-0 w-100">
		<div id="search-box" class="elevation-1 bg-primary rounded">
			<v-img style="height: 80px !important;" :src="require('../assets/img/moe-city.png')" cover
				class="h-auto rounded-t"></v-img>
			<v-row class="align-end justify-end">
				<v-col cols="12" sm="12" md="6" lg="6">
					<v-row class="justify-center xs-mt-8 pt-sm-8 py-md-8">
						<v-col cols="10" sm="10" md="8" lg="8">
							<v-text-field id="policyInput" text="text" hide-details="true"
								label="Policy / renewal quote number and module (FPP123456700)" v-model="policyNumberInput" variant="solo"
								width="40px" density="comfortable" @keyup.enter="fetchPolicy()" @change="updatePolicyInput"></v-text-field>
						</v-col>
						<v-col cols="10" sm="10" md="2" lg="2">
							<m-btn height="48px" width="120px" color="secondary" class="font-weight-bold "
								@click="fetchPolicy()" text="Import"></m-btn>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="12" md="6" lg="6">
					<v-row class="justify-center xs-mb-8 pb-sm-8 py-md-8">
						<v-col cols="10" sm="10" md="8">
							<v-text-field id="addressInput" hide-details="true" label="Enter an address or latitude, longitude"
								variant="solo" v-model="addressInput" @change="updateAddressInput" width="40px" density="comfortable"></v-text-field>
						</v-col>
						<v-col cols="10" sm="10" md="2">
							<m-btn height="48px" width="120px" color="secondary" class="font-weight-bold"
								@click="addLocation()" text="Add"></m-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div>

		<v-container fluid class="my-10 mx-0 pa-3 text-start elevation-1 rounded" v-if="displayPolicyDetails">
			<v-row class="bg-primary rounded-t text-lg font-weight-bold text-start mb-3">
				<v-col cols="12">Policy Highlights</v-col>
			</v-row>
			<v-chip
				v-for="chip in chips"
				class="ma-2"
				size="large"
				:key="chip.id"
				:class="chip.type"
				:color="chip.color"
				outlined
				label
			>
				<v-icon start :icon="chip.icon"></v-icon>
				{{ chip.text }}
			</v-chip>
		</v-container>

		<v-container fluid class="my-10 mx-0 pa-3 elevation-1 rounded policyLossRatios" v-if="lossRatio">
			<v-row class="bg-primary rounded-t text-lg font-weight-bold text-start">
				<v-col cols="12">Policy Loss Ratios</v-col>
			</v-row>
			<!-- First Row: Expiring Term and Three Years by Policy Type -->
			<v-row class="text-start">
				<v-col cols="2">
					<h5>Expiring Term by Policy Type</h5>
					<table class="w-100">
						<thead>
							<tr>
								<th>Policy Type</th>
								<th class="text-end">Loss Ratio</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(term, index) in lossRatio.expiringTermLossRatios" :key="index">
								<td >{{term.lossDescription}}</td>
								<td class="text-end">{{ formatRatio(term.lossRatio)}}</td>
							</tr>
						</tbody>
					</table>
				</v-col>
				<v-col cols="2">
					<h5>Three Years by Policy Type</h5>
					<table>
						<thead>
							<tr>
								<th>Policy Type</th>
								<th class="text-end">Loss Ratio</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(term, index) in lossRatio.threeYearsLossRatios" :key="index">
								<td >{{term.lossDescription}}</td>
								<td class="text-end">{{ formatRatio(term.lossRatio)}}</td>
							</tr>
						</tbody>
					</table>
				</v-col>
				<v-col cols="8" >
					<h5>Three Years Loss Activity</h5>
					<table >
						<thead>
							<tr>
								<th>Claim Number</th>
								<th>DOL</th>
								<th>Loss Description</th>
								<th class="text-end">Total Loss</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(claim, index) in lossRatio.claims" :key="index">
								<td >{{claim.claimNumber}}</td>
								<td >{{ formatDate(claim.dol)}}</td>
								<td style="max-width: 300px;" >{{claim.lossDescription}}</td>
								<td class="text-end">{{ formatCurrency(claim.totalLoss)}}</td>
							</tr>
						</tbody>
					</table>
				</v-col>
			</v-row>
		</v-container>

		<v-container fluid v-for="(location, index) in resultsInput " :key="index"
			class="mt-10 h-auto rounded elevation-1 resultsBox ml-0 mr-0 w-100 pa-0">
			<v-container fluid class="ma-0 pa-0 w-100">
				<v-row class="bg-primary font-weight-bold text-lg ma-0 rounded-t align-center">
					<v-col cols="4" class="text-start">
						Address Location: {{ location.locNum }}
					</v-col>
					<v-col cols="3" class="text-start">
						Total Insured Value:
					</v-col>
					<v-col cols="2">
						Fire Score
					</v-col>
					<v-col cols="2">
						Hail Score
					</v-col>
					<v-col cols="1" class="text-end">
						<m-btn @click="removeLocation(index)" size="small" text="Remove" color="secondary" v-if="location.manuallyAdded"
							class="font-weight-bold"></m-btn>
					</v-col>
				</v-row>
				<v-row class="ma-3 d-flex justify-start align-start">
					<v-col cols="4" class="text-start">
						{{ location.address }}
					</v-col>
					<v-col cols="3">
						<v-row fluid no-gutters v-for="(insLine, insIndex) in location.insLineTIVs" :key="insIndex"
							class="d-flex ma-0 pa-0 mb-2">
							<v-col class="text-start pa-0 ma-0">
								{{ insLine.insLine }}:
							</v-col>
							<v-col class="text-end pa-0 ma-0">
								{{ formatCurrency(insLine.tiv) }}
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="2">
						{{ location.fireScore }}
					</v-col>
					<v-col cols="2">
						{{ location.hailScore }}
					</v-col>
				</v-row>
			</v-container>
			<v-alert v-if="displayError[index] === true" closable icon="mdi mdi-alert"
				text="WARNING: Location TIV exceeds $10,000,000 and may require manager referral. Check your LOA and referral history"
				variant="tonal" class="font-weight-bold bg-warningAddress text-start my-5 mx-2 " ></v-alert>

			<v-alert v-if="location.partialMatch === true" closable icon="mdi mdi-home-alert"
				text="WARNING: This address may not be an exact match. Enter latitude, longitude." variant="tonal"
				class="font-weight-bold bg-warningLabel text-start my-5 mx-2"></v-alert>

			<div id="mapContainer" style="width: auto; height: auto">
				<GoogleMap id="mapId" class="mapOcurrance" api-key="AIzaSyCeDaVPyjqmNXZBlG2H0Uv3UNc41pmgjes"
					style="width: 100%; height: 400px" :center="location.location" :zoom="19" :tilt="45"
					:map-type-id="'hybrid'" :ref="(el) => setMapRef(el as any, index)">
					<Marker :options="{ position: location.location }" />
				</GoogleMap>
			</div>
			<v-row class="justify-space-between align-center rounded-b bg-primary ma-0">
				<v-col cols="2">
					<p class="text-center text-lg font-weight-bold m-0">Location notes:</p>
				</v-col>
				<v-col cols="10">
					<v-textarea clearable hide-details="true" :auto-grow=true class="mx-2" rows="1" label="Comments"
						variant="solo" placeholder="Enter any comments on location"></v-textarea>
				</v-col>
			</v-row>
		</v-container>
	</v-container>

</template>

<script lang="ts">
import MBtn from '@/components/BaseButton.vue';
import { getPropertyInfo } from '@/services/PropertyService';
import { CommercialDataService, electronicWorksheetURL } from '../services/CommercialDataService';
import { PolicyService } from '../contracts/PolicyService';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GoogleMap, Marker } from 'vue3-google-map';
import { ResultsInput } from '@/models/CommercialDataModel';
import { onMounted, ref, computed, inject } from 'vue';
import WaitDialog from '@/components/WaitDialog.vue';
import { Tell } from '@/services/Tell';
import { useTheme } from 'vuetify';
import { LossRatioResponse } from '@/models/PolicyModels';
import moment from 'moment';
import { useRoute } from 'vue-router';
/* global google */

export class LocationMap {
	map: InstanceType<typeof GoogleMap>;
	index: number;

	constructor(init?: Partial<LocationMap>) {
		Object.assign(this, init);
	}
}

export class Chip {
	id: number;
	type: 'negative' | 'positive' | 'warning';
	text: string;

	constructor(init?: Partial<Chip>) {
		Object.assign(this, init);
	}

	get color(): string {
		switch(this.type)
		{
			case 'positive':
				return 'rgb(var(--v-theme-success))';
			case 'negative':
				return 'rgb(var(--v-theme-error))';
			case 'warning':
				return 'rgb(var(--v-theme-warning))';
			default:
				return 'rgb(var(--v-theme-primary))';
		}
	}

	get icon(): string {
		switch(this.type)
		{
			case 'positive':
				return 'mdi-check';
			case 'negative':
				return 'mdi-alpha-x-box';
			case 'warning':
				return 'mdi-alert-box';
			default:
				return '';
		}
	}
}

export default {
	name: 'ViewWorkbench',
	route: {
		path: '/workbench',
		meta: {
			requiresAuth: true,
			title: 'Underwriter Workbench'
		}
	},
	// Add any necessary data, methods, or lifecycle hooks here
	components: {
		MBtn,
		GoogleMap,
		Marker,
		WaitDialog
	},
	setup() {
		const giaResult = ref(null);
		const policyNumberInput = ref('');
		const addressInput = ref('');
		const center = ref({ lat: 47.2031139, lng: -121.9919582 });
		const isWaiting = ref(false);
		const drawer = ref(true);
		const rail = ref(true);
		const resultsInput = ref<ResultsInput[]>([]);
		const mapRefs = ref<typeof GoogleMap[]>([]);
		const testMap = ref(null);
		const displayPolicyDetails = ref<boolean>(false);
		const plrReportUrlDefault = process.env.VUE_APP_PLTR_REPORT_URL;
		const plrReportUrlPolicy = ref(plrReportUrlDefault);
		const electronicWorksheetURLValue = ref(electronicWorksheetURL);
		const theme = useTheme();
		const themeIsDark = ref<boolean>(null);
		const displayError = ref<Array<any>>([]);
		const billingPolicyEvaluation = ref<string>(undefined);
		const lossRatio = ref<LossRatioResponse>(null);
		const chips = ref<Chip[]>([]);

		const tell = inject('tell') as Tell;
		const telemetry = inject('telemetry') as ApplicationInsights;
		const policyService = inject('policyService') as PolicyService;
		const route = useRoute();
		const placeResult = ref<google.maps.places.PlaceResult>(null);

		const fetchPropertyInfo = async (lat: string, long: string) => {
			const propertyInfo = await getPropertyInfo(lat, long);
			giaResult.value = propertyInfo;
		};

		const formatCurrency = (value: number): string => {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(value);
		};

		const formatRatio = (ratio: string): string => {
			var value = parseFloat(ratio);

			return new Intl.NumberFormat('en-US', {
				style: 'percent',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(value);
		};

		const drawerColor = computed<string>(() => {
			//light mode needs to use the accent colors
			return theme.current.value.dark ? '' : 'secondary';
		});

		const sectionColor = computed<string>(() => {
			//light mode needs to use the accent colors
			return theme.current.value.dark ? 'rgb(var(--v-theme-secondary))' : 'bg-primary';
		});

		const formatDate = (date: string): string => {
			return moment(date).format('MM/DD/YYYY');
		};

		const updatePolicyInput = (event: Event) => {
			//uppercase everything
			const target = (event.target as HTMLInputElement);

			target.value = target.value.toUpperCase();

			policyNumberInput.value = target.value;
		};

		const resetWorkbench = () =>
		{
			displayPolicyDetails.value = false;
			isWaiting.value = false;
			resultsInput.value = [];
			mapRefs.value = [];
			chips.value = [];
			lossRatio.value = null;
		}

		const fetchPolicy = async () => {

			try {
				//TODO: do we REALLY want to clear results out? what if they miskeyed a policy number?
				resetWorkbench();

				if (policyNumberInput.value === null || policyNumberInput.value === '') {
					tell.error('Please enter a valid policy number');
										
					return;
				}
				else {
					isWaiting.value = true;

					const res = await CommercialDataService.importPolicy(policyNumberInput.value);
					
					await calculateTIVs(res);

					//loss ratios, and billing center is only for policies, not quotes
					displayPolicyDetails.value = policyNumberInput.value.indexOf("Q") === -1;
					
					if(displayPolicyDetails.value)
					{
						billingPolicyEvaluation.value = await CommercialDataService.setPolicyEvaluation();

						lossRatio.value = await policyService.getLossRatio(policyNumberInput.value);

						// Sort claims by dol property descending
						lossRatio.value.claims.sort((a, b) => moment(b.dol).valueOf() - moment(a.dol).valueOf());

						//check billing center evaluation
						if(billingPolicyEvaluation.value === 'Acceptable')
						{
							chips.value.push(new Chip({ type: 'positive', text: 'Pays bills on time', id: chips.value.length + 1 }));
						}
						else if(billingPolicyEvaluation.value === 'Poor')
						{
							chips.value.push(new Chip({ type: 'negative', text: 'Does not pay bills on time', id: chips.value.length + 1 }));
						}
						else 
						{
							//show whatever is returned as a warning; services could have failed, not available, etc
							chips.value.push(new Chip({ type: 'warning', text: billingPolicyEvaluation.value, id: chips.value.length + 1 }));
						}

						//if they have claims, show that they have claims
						if (lossRatio.value.threeYearsClaimsLossRatios != null && lossRatio.value.threeYearsClaimsLossRatios.claims > 0) 
						{
							chips.value.push(new Chip({ type: 'warning', text: `${lossRatio.value.threeYearsClaimsLossRatios.claims} claims in the last 3 years`, id: chips.value.length + 1 }));
						}
						else
						{
							chips.value.push(new Chip({ type: 'positive', text: 'No claims in the last 3 years', id: chips.value.length + 1 }));
						}

						//now check to see if they have open claims
						if (lossRatio.value.threeYearsClaimsLossRatios != null && lossRatio.value.threeYearsClaimsLossRatios.openClaims > 0) 
						{
							chips.value.push(new Chip({ type: 'warning', text: `${lossRatio.value.threeYearsClaimsLossRatios.openClaims} open claims`, id: chips.value.length + 1 }));
						}
						else
						{
							chips.value.push(new Chip({ type: 'positive', text: 'No open claims', id: chips.value.length + 1 }));
						}

						//now check to see if they have a high loss ratio
						if(lossRatio.value.threeYearsClaimsLossRatios != null)
						{
							var chipType = 'positive';

							if(lossRatio.value.threeYearsClaimsLossRatios.lossRatio >= .61)
							{
								chipType = 'negative';	
							}
							else if(lossRatio.value.threeYearsClaimsLossRatios.lossRatio >= .46)
							{
								chipType = 'warning';
							}

							chips.value.push(new Chip({ type: chipType as 'negative' | 'positive' | 'warning', text: `3 year loss ratio: ${ formatRatio(lossRatio.value.threeYearsClaimsLossRatios.lossRatio.toString()) ?? "not found"}`, id: chips.value.length + 1 }));
						}
					}

					let appendedPolicyQuery = policyNumberInput.value.slice(0, 10);
					plrReportUrlPolicy.value = `${plrReportUrlDefault}?PolicyNumber1=${appendedPolicyQuery}`;
					
					//time to show details
					resultsInput.value = res;
					isWaiting.value = false;
				}
			} catch (error) {
				resetWorkbench();
			}
		};

		const addLocation = async () => {
			try {
				if (addressInput.value === null || addressInput.value === '') {
					tell.error('Please enter a valid address');

					return;
				} else {
					isWaiting.value = true;

					const manualLocation = await CommercialDataService.addLocation(addressInput.value);

					if(manualLocation != null)
					{
						//this location was manually added; therefore it can be removed from the view
						manualLocation.manuallyAdded = true;

						await calculateTIVs(manualLocation);

						resultsInput.value.push(manualLocation);
					}
					
					isWaiting.value = false;
				}
			} catch (error) {
				//anything to do here?	
			}
		};

		const setMapRef = (el: typeof GoogleMap, index: number) => {
			mapRefs.value[index] = el;
		};

		const sendDocument = async () => {
			if (theme.current.value.dark === true) {
				theme.global.name.value = 'light';
				//Boolean value used to determine if the user previously had their theme set to dark
				themeIsDark.value = true;
			}

			await CommercialDataService.exportDocument(mapRefs.value);

			//Value will only be true if user has trys exporting document when they have dark mode
			if (themeIsDark.value === true) {
				theme.global.name.value = 'dark';
			}
		};

		const updateAddressInput = (event: Event) => {
			addressInput.value = (event.target as HTMLInputElement).value;
		};

		const removeLocation = (index: number) => {
			resultsInput.value.splice(index, 1);
			mapRefs.value.splice(index, 1);
		};

		const initAutocomplete = () => {
			const input = document.getElementById('addressInput') as HTMLInputElement;

			if (input) {
				/* eslint-disable */

				const autoCompleteOptions = {
					componentRestrictions: { country: "us" },
					fields: ["address_components", "geometry", "icon", "name"],
					strictBounds: false
				};

				const autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);

				autocomplete.addListener('place_changed', async () => {
					isWaiting.value = true;

					//when the place changes, we want to automatically add the location
					placeResult.value = autocomplete.getPlace();

					//add a direct match
					const newPlace = await CommercialDataService.addPlace(placeResult.value, false);

					newPlace.manuallyAdded = true;

					resultsInput.value.push(newPlace);

					//clear the address we just added to avoid confusion
					addressInput.value = '';
					isWaiting.value = false;

					tell.success('Location added.');
				});
			}
		};

		const calculateTIVs = async (result: any) => {
			let total = 0;
			let limit = 10000000;

			if (displayError.value.length > 0) {
				displayError.value = [];
			}

			for (let i = 0; i < result.length; i++) {
				for (let x = 0; x < result[i].insLineTIVs.length; x++) {
					total += result[i].insLineTIVs[x].tiv;
				}
				if (i !== result.length) {
					if (total > limit) {
						displayError.value.push(true);
					} else {
						displayError.value.push(false);
					}
				}
				total = 0;
			}
		};

		onMounted(async () => {
			initAutocomplete();

			if(route.query.policynumber)
			{
				policyNumberInput.value = String(route.query.policynumber);

				await fetchPolicy();
			}
		});

		return {
			chips,
			giaResult,
			policyNumberInput,
			addressInput,
			center,
			isWaiting,
			drawer,
			rail,
			resultsInput,
			testMap,
			fetchPropertyInfo,
			fetchPolicy,
			addLocation,
			sendDocument,
			formatCurrency,
			formatRatio,
			formatDate,
			displayPolicyDetails,
			lossRatio,
			initAutocomplete,
			removeLocation,
			plrReportUrlPolicy,
			electronicWorksheetURLValue,
			mapRefs,
			setMapRef,
			drawerColor,
			sectionColor,
			displayError,
			calculateTIVs,
			billingPolicyEvaluation,
			updateAddressInput,
			updatePolicyInput
		};
	},
}
</script>

<style scoped lang="scss">
/* Add your custom styles here */
.search {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.background {
	background-image: url('../assets/img/moe-city.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.billingCenterEval {
	border-top: 5px solid rgba(var(--v-theme-accent));
	border-bottom: 5px solid rgba(var(--v-theme-accent));
	font-weight: 600 !important;
}

.policyLossRatios {
	table {
		width: 100%;
		th {
			text-align: left;
		}
		td { vertical-align: top; }
	}
}

@media (max-width: 600px) {
	.xs-mt-8 {
		margin-top: 8px !important;
	}

	.xs-mb-8 {
		margin-bottom: 8px !important;
	}
}
</style>
