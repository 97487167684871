
import { ErrorService } from "./ErrorService";

export async function getPropertyInfo(latitude: string, longitude: string): Promise<GiaResult> {
  const url = `${process.env.VUE_APP_MOE_COMMERCIAL_API_URL}/gia/getInfo`;

  // try {
    const response = await fetch(url.toString(),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.VUE_APP_MOE_COMMERCIAL_API_KEY,
      },
      body: JSON.stringify({ latitude, longitude}),
      
    });

    if (!response.ok) {
      ErrorService(response, 'getPropertyInfo');
    }else{
      const data: GiaResult = await response.json();
      return data;
    }
}

export interface GiaResult {
    messages: string[];
    giaScore: number;
    hailScore: number;
}