<template>
	<div></div>
</template>
 
<script lang="ts">
import { ref, onMounted, inject } from 'vue';
import { AuthService } from '@/contracts/AuthService';
import { AccountInfo } from '@azure/msal-browser';

export default {
	//name doesn't match the file name, but we're doing it like this for uri simplicity on Entra config
	//and for clarity on the vue app
	name: 'HomePage',
	route: {
		path: '/',
		meta: {
			requiresAuth: true,
			title: 'Commercial Web'
		}
	},
	components: {
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	setup() {
		const auth = inject<AuthService>('auth');
		const name = ref<string>('');
		const email = ref<string>('');

		onMounted(async () => {
			if(auth)
			{
				await auth.getActiveAccount().then((account: AccountInfo) => {
					name.value = account.name;
					email.value = account.username;
				});
			}
		});

		return {
			name,
			email,
			//this is the data that is local to this component and reactive
			//use the 'reactive' keyword to make objects and arrays reactive
			//use the 'ref' keyword for simple types
			logout: async () => {
				if(auth)
				{
					auth.logout();
				}
			}
		}
	}
}
</script>
<style scoped></style>