<template>
	<v-tooltip :text="tooltip" :disabled="!tooltip">
		<template #activator="{ props }">
			<v-btn rounded="sm" 
				variant="elevated" 
				v-bind="{ ...props, ...$attrs }" 
				:class="[ $attrs.class, 'm-btn']"
				:click="onClick">
				<template v-for="(_, slotName) in $slots" #[slotName]>
					<slot :name="slotName" />
				</template>
			</v-btn>
		</template>
	</v-tooltip>
</template>

<script>
export default {
	name: "m-btn",
	inheritAttrs: false,
	props: {
		tooltip: {
			type: String,
			default: "",
		}
	},
	methods: {
		onClick() {
			this.$emit('click');
		},
	},
	mounted() {
		// Component mounted
	},
};

</script>

<style scoped lang="scss">
.m-btn {
	&.v-btn--disabled, &:disabled {
		opacity: 0.5;
		cursor: not-allowed !important;
	}
}
</style>