import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

const telemetry = new ApplicationInsights({
	config: {
		instrumentationKey: `${String(process.env.VUE_APP_INSIGHTS_INSTRUMENTATION_KEY)}`,
		/* ...Other Configuration Options... */
	}
});

telemetry.loadAppInsights();

// Set global properties
telemetry.addTelemetryInitializer((envelope: ITelemetryItem) => {
	// Every telemetry item should have the environment associated
	if(!envelope.tags) 
	{
		envelope.tags = new Map<string, string>();
		envelope.tags["ai.cloud.role"] = process.env.NODE_ENV;
	}
});

export default telemetry;
