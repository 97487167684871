<template>
	<WaitDialog :isActive="isWaiting" title="Working" />

	<v-navigation-drawer permanent v-model="drawer" :rail="rail" elevation="5" @click="rail = !rail" width="320"
		:color="drawerColor">

		<v-list density="compact" class="mt-3" nav>
			<v-list-item class="text-start" :disabled="rail ? true : false"
				href="https://enumclawpandc.sharepoint.com/:b:/r/sites/FaCTTeam/Shared%20Documents/General/Homer%20Migrated%20Content/UW%20Workbench/UW%20Workbench%20User%20Guide.pdf?csf=1&web=1&e=wWyGUP"
				prepend-icon="mdi-book-open-variant" title="User Guide" value="guide">
			</v-list-item>

			<v-list-item @click="sendDocument()" :disabled="rail ? true : false" class="text-start custom-list-item"
				prepend-icon="mdi-send" title="Send to OnBase" value="onbase"></v-list-item>

			<v-list-item class="text-start" target="_blank" :disabled="rail ? true : false" :href=plrReportUrlPolicy
				prepend-icon="mdi mdi-chart-line" title="Policy Loss Ratio Report" value="report"></v-list-item>

			<v-list-item class="text-start" target="_blank" :disabled="rail ? true : false"
				:href=electronicWorksheetURLValue prepend-icon="mdi-file-document" title="Most Recent Electronic Worksheet"
				value="worksheet"></v-list-item>
		</v-list>

		<template v-slot:append>
			<v-container fluid>
				<v-row justify="end" v-if="!rail">
					<v-btn icon="mdi-chevron-left" variant="text" @click.stop="rail = !rail"></v-btn>
				</v-row>

				<v-row justify="center">
					<v-btn icon="mdi-chevron-right" variant="text" @click.stop="rail = !rail" v-if="rail"></v-btn>
				</v-row>
			</v-container>
		</template>
	</v-navigation-drawer>

	<v-container fluid class="pa-3 ma-0">
		<div id="search-box" class="elevation-13 bg-primary rounded-lg">
			<v-img style="height: 80px !important;" :src="require('../assets/img/moe-city.png')" cover
				class="h-auto rounded-t-lg"></v-img>
			<v-row class="align-end justify-end">
				<v-col cols="12" sm="12" md="6" lg="6">
					<v-row class="justify-center xs-mt-8 pt-sm-8 py-md-8">
						<v-col cols="10" sm="10" md="8" lg="8">
							<v-text-field id="policyInput" text="text" hide-details="true"
								label="Policy / renewal quote number and module (FPP123456700)" v-model="text1" variant="solo"
								width="40px" density="comfortable"></v-text-field>
						</v-col>
						<v-col cols="10" sm="10" md="2" lg="2">
							<m-btn height="48px" width="120px" color="secondary" class="font-weight-bold "
								@click="inputPolicy(text1)" text="Import"></m-btn>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="12" md="6" lg="6">
					<v-row class="justify-center xs-mb-8 pb-sm-8 py-md-8">
						<v-col cols="10" sm="10" md="8">
							<v-text-field id="addressInput" hide-details="true" label="Enter an address or latitude, longitude"
								variant="solo" v-model="text2" width="40px" density="comfortable"></v-text-field>
						</v-col>
						<v-col cols="10" sm="10" md="2">
							<m-btn height="48px" width="120px" color="secondary" class="font-weight-bold"
								@click="getLocation(text2)" text="Add"></m-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div>

		<div id="billingAccepted" class="mb-10 mt-12 text-left d-flex flex-row content-sz" v-if="displayPolicyEvaluation">
			<p class="pa-4 mb-0 policyEval">BillingCenter Policy Evaluation: </p>
			<p
				:class="{ 'pa-4 mb-0 text-red': billingPolicyEvaluation !== 'Acceptable', 'pa-4 mb-0 text-green': billingPolicyEvaluation === 'Acceptable' }">
				{{ billingPolicyEvaluation }}
			</p>
		</div>

		<div v-for="(location, index) in resultsInput " :key="index" id="results-box"
			class="mt-10 h-auto rounded-t-lg elevation-13 resultsBox bg-primary" style="width: 100% !important;">
			<v-table>
				<thead>
					<tr id="title-row" class="titleRow bg-primary">
						<th style="width: 35%;">Address Location: {{ location.locNum }}</th>
						<th style="width: 25%;">
							Total Insured Value:</th>
						<th style="width: 15%;" class="text-center">Fire Score</th>
						<th style="width: 15%;" class="text-center">Hail Score</th>
						<th style="width: 10%;" class="text-end">
							<m-btn @click="removeLocation(index)" size="small" text="Remove" color="secondary"
								class="font-weight-bold"></m-btn>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr id="sub-content" class="subContent bg-primaryMoe font-weight-bold text-capitalize">
						<td style="width: 35%;" class="text-start">{{ location.address }}</td>
						<td style="width: 25%;" class="text-start">
							<v-row no-gutters v-for="(insLine, insIndex) in location.insLineTIVs" :key="insIndex" class="w-75">
								<v-col class="justify-start py-0 pl-0">
									<p class="my-2">{{ insLine.insLine }}:</p>
								</v-col>
								<v-col class="justify-end py-0 pl-0">
									<p class="my-2 text-end">{{ formatCurrency(insLine.tiv) }}</p>
								</v-col>
							</v-row>
						</td>
						<td style="width: 15%;" class="text-center">{{ location.fireScore }}</td>
						<td style="width: 15%;" class="text-center">{{ location.hailScore }}</td>
						<td style="width: 10%;" class="text-end">
						</td>
					</tr>
				</tbody>
			</v-table>
			<v-alert
				v-if="displayError[index] === true"
				closable
				icon="mdi mdi-alert"
				text="WARNING: Location TIV exceeds $10,000,000 and may require manager referral. Check your LOA and referral history"
				variant="tonal"
				class="font-weight-bold m-2 bg-warningAddress"
				style="margin: 10px;"
			></v-alert>

			<v-alert
				v-if="location.partialMatch === true"
				closable
				icon="mdi mdi-home-alert"
				text="WARNING: This address may not be an exact match. Enter latitude, longitude."
				variant="tonal"
				class="font-weight-bold m-2 bg-warningLabel"
				style="margin: 10px;"

			></v-alert>
			<div id="mapContainer" style="width: auto; height: auto">
				<GoogleMap id="mapId" class="mapOcurrance"
					api-key="AIzaSyCeDaVPyjqmNXZBlG2H0Uv3UNc41pmgjes"
					style="width: 100%; height: 400px" 
					:center="location.location" :zoom="19" :tilt="45" :map-type-id="'hybrid'"
					:ref="(el) => setMapRef(el as any, index)" >
					<Marker :options="{ position: location.location }" />
				</GoogleMap>
			</div>
			<!-- <div id="map" class="h-100 w-100"></div> -->
			<div class="content-sz">
				<v-row class="pa-4 justify-space-between align-center rounded-b-lg">
					<v-col cols="2">
						<p class="text-center m-0">Location notes:</p>
					</v-col>
					<v-col cols="10">
						<v-textarea clearable hide-details="true" :auto-grow=true class="mx-2" rows="1" label="Comments"
							variant="solo" placeholder="Enter any comments on location"></v-textarea>
					</v-col>
				</v-row>
			</div>
		</div>
	</v-container>

</template>

<script lang="ts">
import MBtn from '@/components/BaseButton.vue';
import { getPropertyInfo } from '@/services/PropertyService';
import { CommercialDataService, electronicWorksheetURL } from '../services/CommercialDataService';
import { GoogleMap, Marker } from 'vue3-google-map';
import { ResultsInput } from '@/models/CommercialDataModel';
import { onMounted, ref, computed } from 'vue';
import WaitDialog from '@/components/WaitDialog.vue';
import { Tell } from '@/services/Tell';
import { useTheme } from 'vuetify';

export class LocationMap {
	map: InstanceType<typeof GoogleMap>;
	index: number;

	constructor(init?: Partial<LocationMap>) {
		Object.assign(this, init);
	}
}

export default {
	name: 'ViewWorkbench',
	route: {
		path: '/workbench',
		meta: {
			requiresAuth: true,
			title: 'Underwriter Workbench'
		}
	},
	// Add any necessary data, methods, or lifecycle hooks here
	components: {
		MBtn,
		GoogleMap,
		Marker,
		WaitDialog
	},
	setup() {
		const giaResult = ref(null);
		const text1 = ref('');
		const text2 = ref('');
		const center = ref({ lat: 47.2031139, lng: -121.9919582 });
		const mapVisible = ref(false);
		const isWaiting = ref(false);
		const drawer = ref(true);
		const rail = ref(true);
		const resultsInput = ref<ResultsInput[]>([]);
		const mapRefs = ref<typeof GoogleMap[]>([]);
		const testMap = ref(null);
		const displayPolicyEvaluation = ref(false);
		const plrReportUrlDefault = process.env.VUE_APP_PLTR_REPORT_URL;
		const plrReportUrlPolicy = ref(plrReportUrlDefault);
		const electronicWorksheetURLValue = ref(electronicWorksheetURL);
		const tell = new Tell();
		const theme = useTheme();
		const themeIsDark = ref<boolean>(null);
		const displayError = ref<Array<any>>([]);
		const billingPolicyEvaluation = ref<string>(undefined);

		const fetchPropertyInfo = async (lat: string, long: string) => {
			const propertyInfo = await getPropertyInfo(lat, long);
			giaResult.value = propertyInfo;
		};

		const formatCurrency = (value: number): string => {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			}).format(value);
		};

		const drawerColor = computed<string>(() => {
			//light mode needs to use the accent colors
			return theme.current.value.dark ? '' : 'secondary';
		});

		const inputPolicy = async (policyQuery: string) => {

			try {
				if (policyQuery === '' || policyQuery === null) {
					tell.error('Please enter a valid policy number');
					billingPolicyEvaluation.value = "Not Accepted";
					isWaiting.value = false;
					mapVisible.value = false;
					displayPolicyEvaluation.value = false;
					resultsInput.value = [];
					return;
				} else {
					displayPolicyEvaluation.value = false;
					isWaiting.value = true;
					resultsInput.value = [];
					mapRefs.value = [];
					const res = await CommercialDataService.importPolicy(policyQuery);
					await calculateTIVs(res);
					billingPolicyEvaluation.value = await CommercialDataService.setPolicyEvaluation();
					let appendedPolicyQuery = policyQuery.slice(0, 10);
					plrReportUrlPolicy.value = `${plrReportUrlDefault}?PolicyNumber1=${appendedPolicyQuery}`;
					displayPolicyEvaluation.value = true;
					resultsInput.value = res;
					isWaiting.value = false;
					mapVisible.value = true;
				}
			} catch (error) {
				isWaiting.value = false;
				billingPolicyEvaluation.value = "Not Accepted";
				isWaiting.value = false;
			}
		};

		const getLocation = async (address: string) => {
			try {
				if (address === null || address === '') {
					displayPolicyEvaluation.value = false;
					isWaiting.value = false;
					mapVisible.value = false;
					tell.error('Please enter a valid address');
					resultsInput.value = [];
					return;
				} else {
					isWaiting.value = true;
					displayPolicyEvaluation.value = false;
					resultsInput.value = [];
					mapRefs.value = [];
					const res = await CommercialDataService.addLocation(address);
					await calculateTIVs(res);
					resultsInput.value = res;
					isWaiting.value = false;
					mapVisible.value = true;
				}
			} catch (error) {
				billingPolicyEvaluation.value = "Not Accepted";
				console.error('Error getting location:', error);
			}
		};

		const setMapRef = (el: typeof GoogleMap, index: number) => {
			mapRefs.value[index] = el;
		};

		const sendDocument = async () => {
			if (theme.current.value.dark === true){
				theme.global.name.value = 'light';
				//Boolean value used to determine if the user previously had their theme set to dark
				themeIsDark.value = true;
			}
			await CommercialDataService.exportDocument(mapRefs.value);
			//Value will only be true if user has trys exporting document when they have dark mode
			if(themeIsDark.value === true){
				theme.global.name.value = 'dark';
			}
		};

		const removeLocation = (index: number) => {
			resultsInput.value.splice(index, 1);
		};

		const initAutocomplete = () => {
			const input = document.getElementById('addressInput') as HTMLInputElement;
			if (input) {
				/* eslint-disable */
				const autocomplete = new google.maps.places.Autocomplete(input);
				autocomplete.addListener('place_changed', () => {
					const place = autocomplete.getPlace();
					if (place.geometry) {
						text2.value = place.formatted_address || '';
					}
				});
			}
		};

		const calculateTIVs = async (result: any) => {
			let total = 0;
			let limit = 10000000;

			if (displayError.value.length > 0){
				displayError.value = [];
			}

			for (let i = 0; i < result.length; i++) {
				for (let x = 0; x < result[i].insLineTIVs.length; x++){
					total += result[i].insLineTIVs[x].tiv;
				}
				if (i !== result.length){
					if (total > limit){
						displayError.value.push(true);
					}else{
						displayError.value.push(false);
					}
				}
				total = 0;
			}
		};


		onMounted(() => {
			initAutocomplete();
		});

		return {
			giaResult,
			text1,
			text2,
			center,
			mapVisible,
			isWaiting,
			drawer,
			rail,
			resultsInput,
			testMap,
			fetchPropertyInfo,
			inputPolicy,
			getLocation,
			sendDocument,
			formatCurrency,
			displayPolicyEvaluation,
			initAutocomplete,
			removeLocation,
			plrReportUrlPolicy,
			electronicWorksheetURLValue,
			mapRefs,
			setMapRef,
			drawerColor,
			displayError,
			calculateTIVs,
			billingPolicyEvaluation
		};
	},
}
</script>

<style scoped>
/* Add your custom styles here */
.search {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.background {
	background-image: url('../assets/img/moe-city.png');
	background-size: cover;
	background-repeat: no-repeat;
}

.content-sz {
	font-size: 16px;
	font-weight: bold;
}

#billingAccepted {
	border-top: 5px solid rgba(var(--v-theme-accent));
	border-bottom: 5px solid rgba(var(--v-theme-accent));
	font-weight: 600 !important;
}

#title-row th {
	font-weight: bold;
	font-size: 16px;
}

@media (max-width: 600px) {
	.xs-mt-8 {
		margin-top: 8px !important;
	}

	.xs-mb-8 {
		margin-bottom: 8px !important;
	}
}
</style>
