import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5262d564"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center pa-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_item = _resolveComponent("v-item")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_item_group = _resolveComponent("v-item-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_dialog, {
      modelValue: $setup.activeDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.activeDialog) = $event)),
      transition: "dialog-bottom-transition",
      fullscreen: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  icon: "mdi-close",
                  onClick: $setup.closeSettings
                }, null, 8 /* PROPS */, ["onClick"]),
                _createVNode(_component_v_toolbar_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Settings")
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_toolbar_items, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      text: "Save",
                      variant: "text",
                      onClick: $setup.closeSettings
                    }, null, 8 /* PROPS */, ["onClick"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_list, {
              lines: "two",
              subheader: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_subheader, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Appearance")
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_item_group, {
                  mandatory: "",
                  "onUpdate:modelValue": [
                    $setup.toggleTheme,
                    _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedTheme) = $event))
                  ],
                  modelValue: $setup.selectedTheme
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, { fluid: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.themes, (t, index) => {
                              return (_openBlock(), _createBlock(_component_v_col, {
                                key: index,
                                cols: "2",
                                md: "3",
                                lg: "2"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_item, { value: t }, {
                                    default: _withCtx(({ isSelected, toggle }) => [
                                      _createVNode(_component_v_card, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_img, {
                                            onClick: toggle,
                                            src: t.img,
                                            style: {"max-height":"100px"}
                                          }, null, 8 /* PROPS */, ["onClick", "src"]),
                                          _createVNode(_component_v_card_title, { class: "d-none d-md-flex" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(t.name) + " " + _toDisplayString(isSelected ? '(x)' : ''), 1 /* TEXT */)
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onUpdate:modelValue", "modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}