import { AuthConfig } from "@/contracts/AuthConfig";

export const EntraAuthConfig: AuthConfig = {

	clientId: String(process.env.VUE_APP_AAD_CLIENT_ID),
	tenantId: String(process.env.VUE_APP_AAD_TENANT_ID),
	authority: `https://login.microsoftonline.com/${String(process.env.VUE_APP_AAD_TENANT_ID)}`,

	msalConfig: {
		auth: {
			clientId: String(process.env.VUE_APP_AAD_CLIENT_ID),
			authority: `https://login.microsoftonline.com/${String(process.env.VUE_APP_AAD_TENANT_ID)}`,
			//redirectUri: `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_USE_HISTORY_MODE_ROUTING ? '' : '#/'}${process.env.VUE_APP_OAUTH2_REDIRECT_ROUTE}`,
			redirectUri: `${String(process.env.VUE_APP_BASE_URL)}/login`,
		},
		cache: {
			cacheLocation: 'localStorage',
		},
	},
	//For now we just want to read the user's profile information, after that we will eventually want permission to use an API
	//scopes: ['https://graph.microsoft.com/User.Read', "https://servicebus.azure.net/.default"],
	scopes: ['https://graph.microsoft.com/User.Read' ],
	// The resulting auth session will have roles. This configuration may be used to place
	// automatic reactive properties right on the auth session object based on the presence of a role.
	roleProperties: {
		// <rolePropertyOnAuthSession>: '<roleNameAsShownInToken>'
		someRole: 'SomeRole'
	},
	signedOutRoute: '/signedout',
	oauth2RedirectRoute: '/oauth2-redirect',
	anonymousRoutePatterns: [],
	baseUrl: ['localhost', '127.0.0.1'].includes(location.hostname) ? location.origin : String(process.env.VUE_APP_BASE_URL),
	usingHistoryModeRouting: process.env.VUE_APP_USE_HISTORY_MODE_ROUTING === 'true',
	tokenAcquisitionTimeoutInMilliseconds: 15 * 1000,
	earlyRefreshInMilliseconds: 300 * 1000,
	retryDelayAfterErrorInMilliseconds: 60 * 1000,
	allowSilentFlow: true,
	allowPopupFlow: false,
	allowRedirectFlow: true,
	enableMsalLogging: true,
	logAuthSession: process.env.VUE_APP_AAD_LOG_AUTH_SESSION === 'true',
}
