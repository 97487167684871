<template>
	<!--todo: handle the auth response and show something to the user based off that state-->
	<!--e.g. did it fail? did they not consent? -->
	<h4>Logging in...</h4>
</template>
 
<script lang="ts">
import { onMounted, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth.store';
import { AuthService } from '@/contracts/AuthService';
import { Tell } from '@/services/Tell'
import { AccountInfo } from '@azure/msal-browser';

export default {
	name: 'DoLogin',
	route: {
		path: '/dologin',
		meta: {
			requiresAuth: false,
			title: 'Logging you in...'
		}
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	setup() {
		const auth = inject('auth') as AuthService;
		const tell = inject('tell') as Tell;
		const router = useRouter();
		const authStore = useAuthStore();

		onMounted(async () => {
			//the line below seems strange, but is required in order for this error to not happen
			//https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress
			if(auth)
			{
				await auth.handleRedirect();

				await auth.getActiveAccount().then(async (account: AccountInfo) =>
				{
					if (account == null) {
						//no account - login!
						await auth.login();
					}
					else {
						//if we have an original value to redirect to, send them that way
						authStore.setName(account.name);
						authStore.setEmail(account.username);

						if (authStore.getPreAuthURL != null) {
							router.push(authStore.getPreAuthURL);
						}
						else {
							//otherwise, send them to the home page
							router.push('/');
						}
					}
				});
			}
			else
			{
				tell.error('Auth service not available');
			}
		});

		return {
			//this is the data that is local to this component and reactive
			//use the 'reactive' keyword to make objects and arrays reactive
		}
	}
}
</script>
<style scoped></style>