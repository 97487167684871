<template>
	<div class="container policySummary p-0">
		<div class="row headers">
			<div class="col-4">
				Policy Number
			</div>
			<div class="col-4">
				Status
			</div>
		</div>
		<div class="row align-items-center">
			<div class="col-4">
				{{ policyData.policyNumber }}
			</div>
			<div class="col-4">
				{{ policyData.statusCode === "V" ? "Verified" : "Pending" }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Tell } from '@/services/Tell';
import { ref, onMounted, inject } from 'vue';

interface PolicySummaryProps {
	policyNumber: string;
}

export default {
	//name doesn't match the file name, but we're doing it like this for uri simplicity on Entra config
	//and for clarity on the vue app
	name: 'PolicySummary',
	props:  {
		//these properties are exposed to consumers of this component
		policyNumber: {
			type: String,
			required: true
		}
	},
	computed: {
	},

	setup(props: PolicySummaryProps) {
		const policyData = ref({
			policyNumber: null,
			statusCode: null
		});

		const isLoading = ref<boolean>(true);
	
		const tell = inject('tell') as Tell;

		onMounted(async () => {
			try {
				// //fetch our policy data from the api
				const response = await fetch(`${process.env.VUE_APP_MOE_COMMERCIAL_API_URL}/policy/${props.policyNumber}/Pending`);

				policyData.value = await response.json();
			} catch (error) {
				tell.error('Failed to load this policy');
			} finally {
				isLoading.value = false;
			}
		});

		return {
			policyData
			//this is the data that is local to this component and reactive
			//use the 'reactive' keyword to make objects and arrays reactive
			//use the 'ref' keyword for simple types
		}
	}
}
</script>
<style scoped>
.headers {
	font-weight: bold;
}

.policySummary {
	border-bottom: 2px solid #000;
	margin-bottom: 35px;
}
</style>