import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { inject } from 'vue';
import { useAuthStore } from '@/stores/auth.store';
import { AuthService } from '@/contracts/AuthService';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// This looks for everything in the /views folder with a .vue extension and imports it
const allViews = require.context('@/views', true, /\.vue$/);

const allKeys = allViews.keys();

// This underscore here allows things to be compiled but omits needing the 'path' value
const viewRoutes: RouteRecordRaw[] = allKeys.map((key: string) => {
	// The componentConfig is the actual vue component module
	const componentConfig = allViews(key);

	// Anything in the 'views' folder should have a route property
	if (!componentConfig.default || !componentConfig.default.route) {
		throw new Error(`Component '${key}' does not have a route property. Stop breaking stuff. Thx.`);
	}

	return {
		...(componentConfig.default.route as RouteRecordRaw),
		component: componentConfig.default,
	} as RouteRecordRaw;
});

const manualRoutes: RouteRecordRaw[] = [
	// Add routes here for things that aren't in the views folder
];

// Combine all view routes with the config routes above
export const router = createRouter({
	history: createWebHistory(),
	routes: [...manualRoutes, ...viewRoutes],
});


// Add a global navigation guard to secure the application w/ sso
router.beforeEach(async (to, from, next) => {
	const auth = inject('auth') as AuthService;
	const telemetry = inject('telemetry') as ApplicationInsights;
	const authStore = useAuthStore();

	// Track every page view
	telemetry.trackPageView({ name: to.path });

	// By default, everything requires authentication unless explicitly set to false
	if (await auth.isAuthenticated() || !to.meta.requiresAuth) {
		// User is authenticated or it isn't necessary due to route meta not requiring auth, go where they want to go
		next();
	} else {
		// Store the path the user is trying to access
		authStore.setPreAuthURL(to.fullPath);

		next('/dologin');
	}
});
