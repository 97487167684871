<template>
	<v-dialog v-model="activeDialog" max-width="350px">
		<v-card>
			<v-card-title class="headline text-center">{{ title }}</v-card-title>
			<v-card-text class="text-center">
				<v-progress-circular indeterminate color="primary" :size="size" width="5">
					<!-- <img class="logo wait-icon spin" :src="require('../assets/img/green_E.svg')" /> -->
					<MutualLogo class="logo wait-icon spin" />
				</v-progress-circular>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ref, watch } from 'vue';
import MutualLogo from './MutualLogo.vue';

export default {
	name: 'WaitDialog',
	components: {
		MutualLogo
	},
	props: {
		isActive: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'Please wait...'
		},
		size: {
			type: Number,
			default: 50
		}
	},

	setup(props) {
		const activeDialog = ref(props.isActive);

		watch(() => props.isActive, (newVal) => {
			activeDialog.value = newVal;
		});

		return {
			activeDialog
		}
	}
}
</script>

<style scoped>
/* Add your component styles here */
</style>
