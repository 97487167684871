import { defineStore } from 'pinia';

interface AuthState {
	preAuthURL: string | null;
	name: string | null;
	email: string | null;
}

export const useAuthStore = defineStore({
	id: 'authStore',
	state: (): AuthState => {
		return {
			preAuthURL: null,
			name: null,
			email: null
		};
	},
	// this tells the store to persist to local storage
	persist: true,
	getters: {
		// getters are reactive
		getPreAuthURL: (state): string | null => state.preAuthURL,
		getName: (state): string | null => state.name,
		getEmail: (state): string | null => state.email
	},
	actions: {
		setPreAuthURL(url: string): void {
			this.preAuthURL = url;
		},
		setName(name: string): void {
			this.name = name;
		},
		setEmail(email: string): void {
			this.email = email;
		},
		clearPreAuthURL(): void {
			this.preAuthURL = null;
		}
	}
});