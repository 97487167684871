<template>
	<!--todo: put this into a 'header' component-->
	<v-app>
		<v-layout>
			<v-app-bar :elevation="1" density="compact" >
				<template v-slot:prepend>
					<MutualLogo />
				</template>

				<v-app-bar-title class="text-start">{{ routeTitle }}</v-app-bar-title>

				<template v-slot:append>
					<!-- <v-btn icon="mdi-magnify"></v-btn>
					<v-badge color="error" content="3" >
						<v-icon icon="mdi-bell" color="primary" ></v-icon>
					</v-badge>
					-->
					<v-btn icon="mdi-cog" @click="showSettings"></v-btn>
					<v-btn >
						<template v-slot:prepend>
							<v-icon>mdi-account</v-icon>
						</template>
						<template v-slot:append>
							{{ authStore.getName }}
						</template>

						<v-menu
							offset-y
							activator="parent"
							>
							<!-- Menu content -->
							<v-list>
								<v-list-item button @click="logout" >
									<v-list-item-title>Log out</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-btn>
					
				</template>
			</v-app-bar>
			<v-main id="main-content" class="d-flex justify-center flex-wrap ma-0" >
				<router-view />
				<AppSettings :isActive="settingsOn" @closed="settingsClosed"/>
			</v-main>
		</v-layout>
	</v-app>
</template>
<script lang="ts">

import { useRoute } from 'vue-router';
import { ref, watchEffect, onMounted, inject } from 'vue';
import { AuthService } from '@/contracts/AuthService';
import { useAuthStore } from '@/stores/auth.store';
import AppSettings from '@/components/AppSettings.vue'; 
import MutualLogo from '@/components/MutualLogo.vue'; 

export default {
	name: 'App',
	components: {
		AppSettings,
		MutualLogo
	},
	setup() {
		const route = useRoute();
		const routeTitle = ref<string>('');
		const auth = inject<AuthService>('auth');
		const settingsOn = ref<boolean>(false);

		const authStore = useAuthStore();

		const messages = ref<number>(0);

		const showSettings = () => {
			settingsOn.value = true;
		}

		const settingsClosed = () => {
			settingsOn.value = false;
		}

		watchEffect(() => {
			routeTitle.value = String(route.meta.title);
		});

		onMounted(async () => {
			//any logic in here?
			//console.log('mounted', authStore.getName);
		});

		return {
			authStore,
			messages,
			settingsOn,
			routeTitle,
			showSettings,
			settingsClosed,
			logout: async () => {
				if (auth) {
					auth.logout();
				}
			}
		}
	}
}
</script>

<style>
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
}

</style>
