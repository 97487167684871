import GoogleMapsLoader from 'google-maps'

export interface PointResult {
	addresses: Address[];
	insureds: Insured[];
	locationsTIV: Array<LocationsTIV>;
	countOfCoveragesWithProperties: number;
	countOfCoveragesWithoutProperties: number;
	lineOfBusiness: string;
	status: string;
	symbol: string;
}

export interface LocationsTIV {
	location: number; // relates this TIV to a specific location/address
	insuranceLine: string; // label used to display the TIV
	tiv: number; // sum of insured dollar value for a given Policy, Module, INSLINE and LOCATION.
}

export interface Address {
	address1: string;
	address2: string;
	city: string;
	location: string;
	locNum: number;
	masterCo: string;
	module: string;
	policyNo: string;
	state: string;
	symbol: string;
	zip: string;
}

export interface Insured {
	effectiveDate: string;
	insuredACBusIndivFlag: string;
	insuredACBusinessName: string;
	insuredACFFirstName1: string;
	insuredACLastName1: string;
	insuredACMiddleName1: string;
	insuredACSuffixCode1: string;
	insuredAddress1: string;
	insuredAddress2: string;
	insuredCity: string;
	insuredCountry: string;
	insuredDBA: string;
	insuredName1: string;
	insuredPhone: string;
	insuredState: string;
	insuredZip: string;
	location: string;
	masterCo: string;
	module: string;
	policyNo: string;
	symbol: string;
}

export interface ResultsInputInsLineTIV {
	insLine: string;
	tiv: number;
}

export interface LatLng {
	lat: number;
	lng: number;
}

export class ResultsInput {
	address: string;
	locNum: number;
	location: google.maps.LatLng;
	fireScore: number;
	hailScore: number;
	message?: string;
	partialMatch?: boolean;
	waitingForScores: boolean;
	//indicates if the location was manually added by the user
	manuallyAdded: boolean;
	insLineTIVs: Array<ResultsInputInsLineTIV> = [];
	highLocationTIVWarningThreshold: number; // FACT-2200

	constructor() {
		this.highLocationTIVWarningThreshold = Number(process.env.VUE_APP_HIGH_LOCATION_TIV_WARNING_THRESHOLD);
		this.waitingForScores = true;
		this.partialMatch = false;
		this.manuallyAdded = false;
	}
}

export class BillingCenterPolicyResult {
	policyNumber: string;
	statusCode: string;
	activityCode: string;
	effectiveDate: string;
	expirationDate: string;
	riskState: string;
	lineOfBusinessCode: string;
	policyTypeCode: string;
	programCode: string;
	availableStatuses: string[];
	totalPremium: number;
	commercialPackageLOB: {
		experienceFactor: number;
	};
	pointLocationCompany: string;
	pointMasterCompany: string;
	commissionPercentage: number;

	constructor(data: any) {
		this.policyNumber = data.policyNumber;
		this.statusCode = data.statusCode;
		this.activityCode = data.activityCode;
		this.effectiveDate = data.effectiveDate;
		this.expirationDate = data.expirationDate;
		this.riskState = data.riskState;
		this.lineOfBusinessCode = data.lineOfBusinessCode;
		this.policyTypeCode = data.policyTypeCode;
		this.programCode = data.programCode;
		this.availableStatuses = data.availableStatuses;
		this.totalPremium = data.totalPremium;
		this.commercialPackageLOB = data.commercialPackageLOB;
		this.pointLocationCompany = data.pointLocationCompany;
		this.pointMasterCompany = data.pointMasterCompany;
		this.commissionPercentage = data.commissionPercentage;
	}
}