import { AuthConfig } from "@/contracts/AuthConfig";
import { TokenCredential, GetTokenOptions, AccessToken } from "@azure/core-auth";
import { PublicClientApplication, AccountInfo, SilentRequest } from "@azure/msal-browser";
import { EntraAuthConfig as entraAuthConfig } from '@/services/EntraAuthConfig'

export class EntraTokenCredentials implements TokenCredential {
	msalInstance: PublicClientApplication | null = null;
	account: AccountInfo;
	authConfig: AuthConfig;

	constructor(msalInstance: PublicClientApplication, account: AccountInfo) {
		this.msalInstance = msalInstance;
		this.account = account;

		this.authConfig = entraAuthConfig;
	}

	async getToken(scopes: string | string[], options: GetTokenOptions = {}): Promise<AccessToken | null> {
		const silentRequest: SilentRequest = {
			//scopes: Array.isArray(scopes) ? scopes : [scopes],
			scopes: this.authConfig.scopes,
			account: this.account
		};

		try {
			const authResponse = await this.msalInstance.acquireTokenSilent(silentRequest);
			
			return {
				token: authResponse.accessToken,
				expiresOnTimestamp: new Date().getTime() + (60 * 60 * 1000)
			};
		} catch (error) {
			console.error("Token acquisition failed", error);
			throw new Error("Failed to acquire token silently");
		}
	}
}