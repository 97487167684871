<template>
	<div class="text-center pa-4">
		<v-dialog
			v-model="activeDialog"
			transition="dialog-bottom-transition"
			fullscreen
		>
			<v-card>
				<v-toolbar>
				<v-btn
					icon="mdi-close"
					@click="closeSettings"
				></v-btn>

				<v-toolbar-title>Settings</v-toolbar-title>

				<v-spacer></v-spacer>

				<v-toolbar-items>
					<v-btn
						text="Save"
						variant="text"
						@click="closeSettings"
					></v-btn>
				</v-toolbar-items>
				</v-toolbar>

				<v-list
					lines="two"
					subheader
				>

					<v-list-subheader>Appearance</v-list-subheader>

					<v-item-group mandatory @update:model-value="toggleTheme" v-model="selectedTheme">
						<v-container fluid>
							<v-row>
								<v-col
									v-for="(t, index) in themes"
									:key="index"
									cols="2"
									md="3"
									lg="2"
								>
									<v-item v-slot="{ isSelected, toggle }" :value="t">
										<v-card  >
											<v-img
												@click="toggle"
												:src="t.img"
												style="max-height: 100px;"
											></v-img>
											<v-card-title class="d-none d-md-flex">{{ t.name }} {{ isSelected ? '(x)' : '' }}</v-card-title>
										</v-card>
									</v-item>
								</v-col>
							</v-row>
						</v-container>
					</v-item-group>
				</v-list>
			</v-card>
		</v-dialog>
	</div>
</template>

<script lang="ts">
import { ref, watch, SetupContext, getCurrentInstance } from 'vue';
import { useTheme } from 'vuetify';
import { useAppStore } from '@/stores/app.store';
import lightModeSvg from '@/assets/img/lightMode.svg';
import darkModeSvg from '@/assets/img/darkMode.svg';
import useSystemSvg from '@/assets/img/useSystem.svg';

interface AppSettingsProps {
	isActive: boolean;
}

export default {
	name: 'AppSettings',
	props: {
		isActive: {
			type: Boolean,
			required: true,
			default: false
		}
	},

	setup(props : any, { emit } : SetupContext ) {
		const instance = getCurrentInstance();
		const appSettingsProps = instance?.proxy.$props as AppSettingsProps;
		const activeDialog = ref(appSettingsProps.isActive);
		const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
		const themes = ref([{
				name: 'light', 
				img: lightModeSvg
			},
			{
				name: 'dark', 
				img: darkModeSvg
			},
			{
				name: 'system', 
				img: useSystemSvg
			}
		]);

		const theme = useTheme();
		const appStore = useAppStore();

		const selectedTheme = ref(themes.value.find(t => t.name === appStore.getTheme));

		const closeSettings = () => {
			activeDialog.value = false;
			emit('closed');
		}

		const toggleTheme = (selection : any) => {

			if(selection.name === 'system') {
				theme.global.name.value = prefersDarkMode ? 'dark' : 'light';
			} else {
				theme.global.name.value = selection.name;
			}
			
			//save this to the app store
			appStore.setTheme(selection.name);
		}

		watch(() => props.isActive, (newVal) => {
			activeDialog.value = newVal;
		});

		return {
			activeDialog,
			themes,
			selectedTheme,
			toggleTheme,
			closeSettings
		}
	}
}
</script>

<style scoped>
/* Add your component styles here */

</style>
