<template>
	<v-container style="margin-top: 30px;">
		<WaitDialog :isActive="isLoading" title="Loading data..." />
		<div v-if="errorMessage" class="error-container">
			<div class="error-message">
				{{ errorMessage }}
				<div class="button-container">
					<base-button @click="dismissError" text="OK"></base-button>
				</div>
			</div>
		</div>
		<div class="container text-start" v-if="policyData && !isLoading">
			<div class="container alert alert-warning" v-if="policyData.transactionType === 'EN'">
				Prior to making deductible changes, please rate your endorsement at least once.
			</div>
			<div class="container policyInfo p-0">
				<v-row class="row headers" align="center">
					<v-col cols="4" >
						Policy Number
					</v-col>
					<v-col cols="4">
						Status
					</v-col>
					<v-col cols="2" class="text-center">
						State
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="4">
						{{ policyNumber }}
					</v-col>
					<v-col cols="4">
						{{ policyData.status === "V" ? "Verified" : "Pending" }}
					</v-col>
					<v-col cols="2">
						<v-select v-model="selectedState" :items="states" variant="solo-filled"
							style="min-width:100px; margin-top: 17px" class="pa-0"></v-select>
					</v-col>
					<v-col cols="2" class="text-end" >
						<m-btn @click="updateDeductibles" text="Update" :disabled="!policyData.canUpdate" color="primary" />
					</v-col>
				</v-row>
			</div>
		</div>
		<v-container class="container" v-if="policyData && policyData.canUpdate && !isLoading">
			<v-container v-for="(lineItem, index) in lineItems" :key="index" class="pa-0">
				<v-container v-for="(coverage, index) in lineItem.coverageGroups" :key="index" class="text-start pa-0"
					data-state="{{lineItem.state}}" ref="lineItemsRef">
					<v-row align="center" class="text-start pa-0" >
						<v-col cols="4" class="text-start pa-0">
							{{ coverage.coverageCodeDescription }}
							<div v-if="lineItem.coverageCode === 'RVPD'">
								<div class="insuranceLine">
									<div class="fw-lighter">
										Line of Business: {{ getInsuranceLineDescription(lineItem.insuranceLineCode) }}
									</div>
								</div>
							</div>
						</v-col>
						<v-col cols="4">
							<v-select label="From" item-title="title" variant="solo-filled" :items="coverage.fromDeductibles"
								v-model="coverage.selectedFrom" clearable return-object>
							</v-select>
						</v-col>
						<v-col cols="4">
							<v-select label="To" item-title="title" variant="solo-filled" :items="coverage.toDeductibles"
								v-model="coverage.selectedTo" clearable return-object></v-select>
						</v-col>
					</v-row>
					<v-row class="includesLine">
						<v-col cols="12" class="fw-lighter pa-4">
							Includes: {{ getBigDescription(coverage.coverageCode) }}
						</v-col>
					</v-row>
				</v-container>
			</v-container>
		</v-container>
	</v-container>
</template>

<script lang="ts">
import { ref, onMounted, watch, inject, reactive } from 'vue';
import { useRoute } from 'vue-router';
import WaitDialog from '@/components/WaitDialog.vue';
import { Tell } from '@/services/Tell';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as models from '@/models/BulkDeductibleViewModels';
import { PolicyService } from '@/contracts/PolicyService';
import MBtn from '@/components/BaseButton.vue';

export default {
	name: 'BulkDeductibles',
	route: {
		path: '/bulk-deductibles',
		meta: {
			requiresAuth: true,
			title: 'Bulk Deductibles'
		}
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	components: {
		WaitDialog,
		MBtn
	},
	setup() {
		//this is where you would put any logic that needs to run when the component is created
		//this is similar to the constructor in a class-based component
		const route = useRoute();

		const tell = inject('tell') as Tell;
		const telemetry = inject('telemetry') as ApplicationInsights;
		const policyService = inject('policyService') as PolicyService;

		//use ref() for simple values to make them reactive
		const policyNumber = ref<string | null>(null);
		const policyData = ref<any>(null);
		const lineItems = ref<any[]>([]);
		const selectedState = ref<string>(null);
		const states = ref<string[]>([]);
		const isLoading = ref<boolean>(false);
		const errorMessage = ref<string | null>(null);

		//currently not used - possible to use somehow?
		const lineItemsRef = ref(null);

		//when selectedState changes, we need to filter our states
		watch(selectedState, (newState: string, oldState: string) => {
			if (newState !== oldState) {
				filterByState(newState);
			}
		});

		const getBigDescription = (coverageCode: string) => {
			let coverages = policyData.value.coverages.filter((c: models.CodeDescription) => c.code == coverageCode);

			return coverages.map((c: models.CodeDescription) => c.description.trimEnd()).join(', ');
		};

		const getInsuranceLineDescription = (insuranceLineCode: string) => {
			let insuranceLine = policyData.value.insuranceLines.find((i: models.CodeDescription) => i.code == insuranceLineCode);

			return insuranceLine ? insuranceLine.description : insuranceLineCode;
		};

		const filterByState = (state: string) => {
			//reset our main bound values
			lineItems.value = [];

			let filteredLineItems = policyData.value.lineItems.filter((c: any) => c.state === state || state === 'All');

			//grab line items for each
			filteredLineItems.forEach((lineItem: any) => {
				lineItem.coverageGroups = lineItem.coverageGroups.map((coverageGroup: any) => {
					const newCoverageGroup = reactive({
						...coverageGroup,
						//extend the model so we can bind to these values
						fromDeductibles: getFromDeductibles(coverageGroup),
						toDeductibles: getToDeductibles(coverageGroup.coverageCode, lineItem.insuranceLineCode),
						//used for binding in the UI 
						selectedFrom: ref(null),
						selectedTo: ref(null)
					});

					return newCoverageGroup;
				});
			});

			//set the ref value for binding
			lineItems.value = filteredLineItems;
		}

		const getFromDeductibles = (coverage: models.CoverageGroupViewModel) => {
			let sorted = coverage.deductibles.sort((x: models.DeductibleViewModel, y: models.DeductibleViewModel) => parseInt(x.deductibleCode) - parseInt(y.deductibleCode));

			return sorted.map((d: models.DeductibleViewModel) => ({
				...d,
				title: d.deductibleCodeDescription.trimEnd()
			}));
		};

		const getToDeductibles = (coverageCode: string, insuranceLineCode: string) => {
			var toDeductibles = policyData.value.toDeductibles
				.filter((d: models.DeductibleViewModel) => d.coverageCode == coverageCode && d.insuranceLineCode == insuranceLineCode && d.state == selectedState.value)
				.sort((x: models.DeductibleViewModel, y: models.DeductibleViewModel) => {
					const numX = parseFloat(x.deductibleCode);
					const numY = parseFloat(y.deductibleCode);

					// If both can be converted to a number, compare as numbers
					if (!isNaN(numX) && !isNaN(numY)) {
						return numX - numY;
					}

					// If one can be converted and the other can't, the one that can be converted comes first
					if (!isNaN(numX)) {
						return -1;
					}
					if (!isNaN(numY)) {
						return 1;
					}

					// If neither can be converted to a number, compare as strings
					return String(x.deductibleCode).localeCompare(String(y.deductibleCode));
					//parseInt(x.deductibleCode) - parseInt(y.deductibleCode)
				});

			return toDeductibles.map((d: models.DeductibleViewModel) => ({
				...d,
				title: d.deductibleCodeDescription
			}));
		}

		//example of how to access refs
		const accessChildren = () => {
			console.log(lineItemsRef);
			lineItemsRef.value?.forEach((childElement: HTMLElement) => {
				// Interact with child components
				console.log(childElement);
			});
		};

		const updateDeductibles = async () => {
			isLoading.value = true;

			try {
				let lineItemsToPost: models.UpdateBulkDeductibleLineItem[] = [];

				//very cool array definition!
				let stuffToPost: { InsuranceLineCode: string, CoverageCode: string, DeductibleCodeFrom: string, DeductibleCodeTo: string }[] = [];

				lineItems.value.forEach((lineItem: any) => {
					lineItem.coverageGroups.forEach((coverageGroup: any) => {
						//disregard null selections
						if (coverageGroup.selectedFrom == null || coverageGroup.selectedTo == null) return;

						// stuffToPost.push(
						// 	{
						// 		"InsuranceLineCode": lineItem.insuranceLineCode,
						// 		"CoverageCode": coverageGroup.coverageCode,
						// 		"DeductibleCodeFrom": coverageGroup.selectedFrom.deductibleCode,
						// 		"DeductibleCodeTo": coverageGroup.selectedTo.deductibleCode
						// 	});
						
						lineItemsToPost.push({
							insuranceLineCode: lineItem.insuranceLineCode,
							coverageCode: coverageGroup.coverageCode,
							deductibleCodeFrom: coverageGroup.selectedFrom.deductibleCode,
							deductibleCodeTo: coverageGroup.selectedTo.deductibleCode
						});
					});
				});

				if (lineItemsToPost.length == 0) {
					tell.error("No changes to make.");

					return;
				}

				let updateDeductiblesPost = new models.UpdateBulkDeductiblePost({
					symbol: policyNumber.value?.substring(0, 3),
					policyNumber: policyNumber.value?.substring(3, 10),
					module: policyNumber.value?.substring(10, 12),
					state: selectedState.value,
					updates: lineItemsToPost
				});

				const response = await policyService.updateBulkDeductibles(updateDeductiblesPost);
				
				if (response.ok) {
					// Handle success
					tell.success('Successfully updated.');

					//reload our data
					await loadData();
				}
				else {
					// Handle error
					let errorData = await response.json();

					//track this exception
					telemetry.trackException(errorData.message);

					tell.error(errorData.message);
					errorMessage.value = errorData.message;
				}

				//reset our UI
				filterByState(selectedState.value);
			} catch (error) {
				errorMessage.value = "An error occurred while updating deductibles.";
			} finally {
				isLoading.value = false;
			}
		};

		const loadData = async function () {
			isLoading.value = true;

			try {
				policyNumber.value = String(route.query.policyNumber);

				// //fetch our policy data from the api
				const response = await policyService.getBulkDeductibles(policyNumber.value);

				policyData.value = response;

				states.value = policyData.value.states;

				//use the first state that is returned to show deductibles
				selectedState.value = states.value[0];
			} catch (error) {
				errorMessage.value = "An error occurred while loading data.";
			} finally {
				isLoading.value = false;
			}
		}

		const dismissError = () => {
			errorMessage.value = null;
		};

		onMounted(async () => {
			await loadData();
		});

		return {
			//this is the data that is local to this component and reactive
			//use the 'reactive' keyword to make objects and arrays reactive
			isLoading,
			errorMessage,
			dismissError,
			states,
			selectedState,
			lineItems,
			policyNumber,
			policyData,
			policyStatus: ref(''),
			getBigDescription,
			getInsuranceLineDescription,
			filterByState,
			updateDeductibles,
			accessChildren,
			lineItemsRef,
			getFromDeductibles,
			getToDeductibles
		}
	}
}
</script>

<style scoped>
/* Your CSS goes here */
.row {
	margin-bottom: 20px !important;
}

.policyInfo {
	border-bottom: 2px solid #000;
	margin-bottom: 35px;
}

.headers {
	font-weight: bold;
}

select {
	width: 200px;
}

.includesLine {
	margin-top: var(--gutter);
}

button:disabled {
	cursor: not-allowed;
}
</style>