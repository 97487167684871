import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { style: {"margin-top":"30px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_WaitDialog, {
        isActive: $setup.isPushing,
        title: "Staring push..."
      }, null, 8 /* PROPS */, ["isActive"]),
      _createVNode(_component_v_dialog, {
        modelValue: $setup.dialog,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        activator: _withCtx(({ props: activatorProps }) => [
          _createVNode(_component_v_btn, _mergeProps(activatorProps, { class: "moe-button" }), {
            default: _withCtx(() => [
              _createTextVNode(" Push Manuals ")
            ]),
            _: 2 /* DYNAMIC */
          }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            "prepend-icon": "mdi-map-marker",
            text: "Are you ready to push the commercial manual pages?",
            title: "Ready to push?"
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_base_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialog = false)),
                text: "Nevermind"
              }),
              _createVNode(_component_base_button, {
                onClick: $setup.pushManuals,
                text: "Let's go!"
              }, null, 8 /* PROPS */, ["onClick"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"])
    ]),
    _: 1 /* STABLE */
  }))
}