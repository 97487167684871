import { PublicClientApplication, AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { AuthService } from "@/contracts/AuthService";
import { AuthConfig } from "@/contracts/AuthConfig";

// simple wrapper for msal functionality
export class EntraAuthService implements AuthService {
	msalInstance: PublicClientApplication | null = null;
	authConfig: AuthConfig;

	constructor(config: AuthConfig) {
		this.authConfig = config;
	}

	async getInstance(): Promise<PublicClientApplication> {
		if (this.msalInstance == null) {
			// create the msal instance and use the auth store config
			const msal = new PublicClientApplication(this.authConfig.msalConfig);

			await msal.initialize();

			this.msalInstance = msal;
		}

		return this.msalInstance;
	}

	async login(): Promise<void> {
		// this initiates a redirect to the login flow
		const msal = await this.getInstance();

		await msal.loginRedirect({
			//scopes: [], // no scopes for now
			scopes: this.authConfig.scopes,
			loginHint: "user@mutualofenumclaw.com"
		});
	}

	async logout(): Promise<void> {
		// this initiates a redirect to the login flow
		const msal = await this.getInstance();

		msal.logout();

		// the line below seems strange, but is required in order for this error to not happen
		// https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress
		await this.handleRedirect();
	}

	async isAuthenticated(): Promise<boolean> {
		const account = await this.getActiveAccount();

		return account != null;
	}

	async getActiveAccount(): Promise<AccountInfo | null> {
		const msal = await this.getInstance();

		const accounts = msal.getAllAccounts();

		return accounts[0] || null;
	}

	async getProfilePicture(): Promise<string | null> {
		const msal = await this.getInstance();

		const account = await this.getActiveAccount();

		if (account) {
			const tokenResponse = await msal.acquireTokenSilent({
				scopes: [],
				account: account
			});

			// download picture
			const headers = new Headers();

			headers.append("Authorization", `Bearer ${tokenResponse.accessToken}`);

			const response = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
				method: 'GET',
				headers: headers
			});

			const imageBlob = await response.blob();

			return URL.createObjectURL(imageBlob);
		}

		return null;
	}

	async handleRedirect(): Promise<AuthenticationResult | null> {
		const msal = await this.getInstance();

		// this handles the redirect from the login flow return this promise to the caller so they can handle it
		const result = await msal.handleRedirectPromise();
				
		return result;
	}
}
