<template>
	<!--todo: handle the auth response and show something to the user based off that state-->
	<!--e.g. did it fail? did they not consent? -->
	<div></div>
</template>

<script lang="ts">
import { onMounted, inject } from 'vue';
import { AuthService } from '@/contracts/AuthService';

export default {
	//name doesn't match the file name, but we're doing it like this for uri simplicity on Entra config
	//and for clarity on the vue app
	name: 'HandleLogin',
	route: {
		path: '/login',
		meta: {
			requiresAuth: false,
			title: 'Checking account...'
		}
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	setup() {
		const auth = inject('auth') as AuthService;

		onMounted(async () => {
			//immediately handle the redirectResponse promise under the msal hood
			//this will send the user back to the page that called the login
			if (auth) {
				await auth.handleRedirect();
			}
		});

		return {
			logout: async () => {
				if (auth) {
					auth.logout();
				}
			}
		}
	}
}
</script>
<style scoped></style>