export class OrderByParam {
	order: string = null;
	direction: string = null;
}

export class PolicySearchRequest {
	scope: "pending";
	lineOfBusinessCodes: string[] = [];
	insuranceLineCodes: string[] = [];
	riskStates: string[] = [];
	coverageTypeCodes: string[] = [];
	policyNumbers: string[] = [];
	effectiveDate = {
		start: "",
		end: ""
	};
	page = 0; // Replace with the actual value for 'page'
	pageSize = 0; // Replace with the actual value for 'itemsPerPage'
	orderBy: OrderByParam[] = [];

	constructor(init?: Partial<PolicySearchRequest>) {
		Object.assign(this, init);
	}
}

export class PolicySearchResponse {
	totalMatchingPolicies = 0;
	query: string = null;
	message: string = null;
	policies: Policy[] = [];
}

export class Policy {
	policyNumber: string = null;
	statusCode: string = null;
	effectiveDate: string = null;
	expirationDate: string = null;
	lineOfBusinessCode: string = null;
	insuranceLineCode: string = null;
	insuranceLineDescription: string = null;

	riskState: string = null;
	totalPremium: number = null;

	//TODO: add available lines
	excessLine: object = null;
}

export class LossRatioDescription {
	lossDescription: string;
	lossRatio: string;
}
export class PolicyClaim {
	claimNumber: string;
	dol: string; // Date in ISO format
	lossDescription: string;
	totalLoss: number;
}

export class ThreeYearsClaims {
	claims: number;
	openClaims: number;
	lossRatio: number;
}

export class LossRatioResponse {
	expiringTermLossRatios: LossRatioDescription[];
	threeYearsLossRatios: LossRatioDescription[];
	claims: PolicyClaim[];
	threeYearsClaimsLossRatios: ThreeYearsClaims;

	constructor(init?: Partial<LossRatioResponse>) {
		Object.assign(this, init);
	}
}