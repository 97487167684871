<template>
	<!-- Generator: Adobe Illustrator 25.4.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 2435.8 2439.8" style="enable-background:new 0 0 2435.8 2439.8;" xml:space="preserve" :class="['logo', $attrs.class]">
		<path :style="{fill: computedColor}"
			d="M1209.1,1649.8V1461l1218.4,0.2c-19.1-156.3-62.4-318-89.6-479.6l-1128.9,0.1V778.4l1108.7,0.1
			c-8.2-167.1,13.8-331.7,116-488.1C2256,348.5,1709.1,580.9,1216.9,0C724.7,580.9,177.8,348.5,0,290.5
			C341.8,813.3-160.8,1430.2,68.4,1917c218.8,464.7,984.5,58,1148.5,522.8c164.1-464.7,929.8-58.1,1155.4-522.8
			c41.4-85.4,59.8-174.9,63.5-267.1L1209.1,1649.8z"/>
	</svg>
</template>
<script>
import { computed } from 'vue';
import { useTheme } from 'vuetify';

export default {
	name: "MutualLogo",
	setup() {
			const theme = useTheme();

			const computedColor = computed(() => {
				return theme.global.current.value.colors.primary;
			});

			return {
				computedColor,
			};
		},
};
</script>
<style scoped>
.logo {
	height: 30px;
	width: 30px;
}
</style>